.nombre__articulo__detalle{
    font-weight: bold;
    color: #574800;
    font-size: 2rem;
    padding-bottom: 0.4em;
}

.articulo__detalle__foto {
    margin-top: 0%;
    margin-bottom: 1rem;
    height: 25vh;
    width: 60vh;
}

.articulo__detalle{
    color: #574800;
    font-size: 1.1rem;
    padding: 0.2rem;
}

.articulo__detalle__basicos {
    font-size: 1.2rem;
    padding: 0.2rem;
    color: #574800;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-contenido {
    background: #FCEDA4;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .cerrar-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 5vh;
    color: rgb(255, 255, 255);
  }
  
  @media screen and (max-width:769px) {

    .nombre__articulo__detalle, .articulo__detalle__basicos, .articulo__detalle{
        font-size: 15px;
    }

    .articulo__detalle__foto {
        margin-top: 0%;
        margin-bottom: 1rem;
        height: 100px;
        width: 200px;
    }

}