.contenedor--principal--inicio{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    
}

.titulo--inicio--principal{
 color: #816E15;
 font-weight: bold;
 font-size: 3rem;
 text-align: center;
 padding: 1rem;
}


.texto--secundario{
    font-weight: bold;
    text-align: center;
    width: 80%;
    color: #573A00;
    font-size: 1.3rem;
}

.imagen-bienvenida{
    width: 80%;
    height: 400px;
    padding: 0.4em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.contenedor__promocion {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
}

.imagen-texto{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #815D15;
    font-size: 1.5em; 
    font-variant: small-caps;
    margin-bottom: 2rem;

}



@media screen and (max-width:769px) {


    .imagen-bienvenida{
        height: 295px;
    }

    .titulo--inicio--principal{

        font-size: 2rem;
    }

    .texto--secundario{
        margin-left: 8px;
        font-size: 1.2rem;
    }

    .imagen-texto{

        text-align: center;
        font-size: 1.15rem;

    }
}
