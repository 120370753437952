.app{
    font-family: 'Montserrat', sans-serif;
    background-color: white;
}


.banner{
    display: block;
    height: 33vh;
    width: 100%;
}

.menu__nav{
    display: flex;
    background: linear-gradient(to right, #4B608D, #162A56);
    border: 0.1em solid #2D4372;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    justify-content: space-around;
    padding: .25rem 0;
}

.enlace__navegacion{
    color: #FCDEA4;
    font-weight: bold;
    font-size: 1.75rem;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 5px;
}

.enlace__navegacion:hover{
    color: #c78c19;
    text-decoration: underline;
}

.principal{
    width: 80%;
    position: relative;
    margin: 0 auto;
}

.principal{
    padding: 1rem;
}

.titulo-de-principal{
    margin: 2rem 0;
    text-align: center;
    font-size: 2.5rem;
}

/*PIE ---- elementos generales donde van todos los elementos*/

.pie__contenedor {
    width: 85%;
    height: 25vh;
    margin: 0 auto;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-flow: row wrap;
    align-content: space-around;
}

.pie__general {
    background: #FCDEA4;
    color: #5E4F91;
    height: 40vh;
}

.pie__elemento{
    margin : 1rem;
}

.pie__titulos {
    font-weight: bold;
    color: #3F2F75;
    font-size: 1.2rem;
    margin-top: 2rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
}
/*------SECCIONES------------------*/
.pie__navegador__lista, .pie__direcciones__lista, .pie__redes__lista {
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
    align-items: center;
}
/*-------------MENU DE NAVEGACION DEL PIE------------- */
.pie__enlace__navegacion {
    color: #5E4F91;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
}

.pie__navegador {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
}
.pie__navegador__lista {
    
    height: 200px;
}
.pie__nav__enlace:hover {
    text-decoration: underline;
}


/* -------------CONTACTO Y DIRECCIONES------------- */
.pie__direcciones__lista {
    
    height: 200px;
}

/* -------------REDES LINKS E ICONOS-------------*/

.pie__general__direcciones{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-start;
    align-items: center;
}
.pie__redes__enlace:hover{
    opacity: 0.5;
    transition: 0.9s;
}
.pie__redes__logo{

    padding: 5px;
    height: 50px;
    width: 50px;
}








/*------------------MOVIL ???? -------------------------------*/

@media screen and (max-width:769px){
    
    .banner{display: none;}

    .menu__nav{
        display: flex;
        justify-content: space-between;
        flex-flow: column wrap;
        padding: .7rem;
        background: linear-gradient(to right, #4B608D, #162A56);
    }
    
    .enlace__navegacion{

        font-size: 1.7rem;
        padding: .5rem;
    }

    .titulo-de-principal{
        font-size: 1.2rem;
    }
    /*---------------PARA DEL PIE PARA MOVILES ???? FTW !!!!*/
    .pie__general {
        height: auto;
    }
    .pie__contenedor {
        flex-direction: column-reverse;
        width: 85%;
        height: auto;
        margin: 0 auto;
        align-items: center;
    }

}
