.contenedor--general--contacto{
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    align-items: center;
    margin-top: 2vh;
}
.titulo--contacto--principal{
    color: #816E15;
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
}

.contenedor--datos--contacto{
    margin-top: 2rem;
    display: flex;
    flex-flow: row wrap;
}
.contenedor--datos--contacto p{
    padding: 5px;
}

.contacto--titulos{
    font-size: 1.5rem;
    font: bold;
    margin: 15px;
    color: #FCDEA4;
}

.contacto--texto--redes{
    color: #f5e2bc;
}

.contacto--info--importante{
    font-weight: bold;
    color: #573A00;
    font-size: 1.1rem;
    margin: 5px;

}

.contenedor--contacto--redes{
   margin-top: 15px;
   margin-bottom: 35px;
   background: linear-gradient(to right, #5E4F91, #06173A);
   width: 70%;
   border-radius: 16px;
   padding: 6px;

}
.redes--logo{
    width: 75px;
    height: 75px;
    padding: 25px;
}
.redes--logo img
{
    padding: 5px;
    cursor: pointer;
    transition: 0.5s;
}

.redes--logo:hover{
    opacity: 0.8;
    transform: scale(1.1);
}

.contacto--contenedor--googlemap{
    display: flex;
    flex-flow: column wrap;
    padding: 10px;
    
    
}

.contacto--contenedor--googlemap iframe{

    border-radius: 16px;
}


@media screen and (max-width:1200px){

.contenedor--datos--contacto{
    flex-flow: column wrap;
}

}

@media screen and (max-width:769px){

    .contenedor--general--contacto{
        width: auto;
    }

    .contacto--contenedor--googlemap iframe{
        width: 40vh;
        margin-top: 15px;   
    }

    .titulo--contacto--principal{
        font-size: 2rem;
    }

    
.redes--logo{
    width: 60px;
    height: 60px;
    padding: 8px;
}

}

@media screen and (max-width:400px){

.contenedor--contacto--redes{
    display: flex;
    flex-flow: row wrap;
    width: auto;
}


}