
.contenedor--productos{
padding-left: 5%;
padding-right: 5%;
padding-top: 1%;
padding-bottom: 25px;
}

.titulo--productos--principal{
    color: #816E15;
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
}


.titulos__categorias__productos {
    text-align: center;
    font-weight: bold;
    color: #261759;
    font-size: 2.5rem;
    padding: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    background: #8478AE;
}

.lista__productos {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
}

.lista__elemento__producto {
    display: inline-block;
    text-align: center;
    margin: 1rem 1rem;
    padding: 1rem;
    box-sizing: border-box;
    border: 2px solid #13073C;
    border-radius: 10px;
}

.lista__elemento__producto:hover {
    background-color: #3F2F75;
    color:#D2AE66;
    box-shadow: 1rem 1rem 1rem 0.2rem #000000;
    opacity: 0.8;
    transform: scale(1.01);
    transition: 0.65s;
}

.productos img{
    width: 100%;
    padding-bottom: 2%;
}

.nombre__articulo{
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

@media screen and (max-width:769px){
    .titulo--productos--principal{
        font-size: 2rem;
    }
}

@media screen and (max-width:400px){

    .nombre__articulo__detalle{
        font-size: 1.6rem;
        text-align: center;
    }

    .articulo__detalle__foto{
        width: 340px;
        height: 155px;
    }

    .articulo__detalle__basicos, .articulo__detalle{
        font-size: 1.1rem;
        padding: 4px;
    }

}



