.contenedor--general--empresa{
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-content: center;
}

.titulo--empresa--principal{
  color: #816E15;
  font-weight: bold;
 font-size: 3rem;
 padding: 1rem;
 text-align: center;
}


/*parte de lado de la pagina de empresa*/
/*miniaturas clickeables, titulos y textos*/

.empresa--miniaturas{
  margin-left: 15%;
  margin-right: 15%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  padding-bottom: 25px;
}
.imagen--empresa--mini{
  width: 300px;
  height: 200px;
  padding: 5px;
  margin: 10px;
  cursor: pointer;
  transition: 0.5s;
}
.imagen--empresa--mini:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.76), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.contenedor--texto--empresa{
  margin: 20px;
  width: 80%;
  text-align: center;
  font-weight: bold;
  color: #573A00;
  border-radius: 16px;
}

.contenedor--texto--empresa p {

  font-weight: 100;
  font-size: large;
  padding: .5em;


}




/**************************************************************
/* parte que pertenece a los estilos del Modal y sus funciones*/
/***************************************************************/

.contenedor--modal--empresa{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.centro--modal--empresa{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

}

.elementos--modal--empresa{
    flex-flow: column wrap;
    display: flex;
    align-content: center;
    align-items: center;
}
.flecha--navegacion--menos,
.flecha--navegacion--mas {
  cursor: pointer;
  width: auto;
  color: white;
  font-weight: bold;
  font-size: 60px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
  padding: 2rem;
}


.cerrar--boton--modal{
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 11vh;
    color: white
}
.imagen--ampliada--modal{
  position: relative;
  height: 65%;
  width: 80%;
}

.contenido--modal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contenedor--leyendas--modal{
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: 10px;

}

.leyenda--modal {
  text-align: center;
  margin-top: 10px;
  font-size: large;
  color: white;
}

.miniaturas--centrales {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.miniatura {
  width: 50px;
  height: 30px;
  margin: 0 5px;
  cursor: pointer;
}

.miniatura--seleccionada {
  border: 2px solid #3498db;
  height: 40px;
  width: 60px;
}


@media screen and (max-width:964px){
  .flecha--navegacion--mas,.flecha--navegacion--menos{
    padding: 1rem;
  }

}

/*PANTALLAS MAS CHICAS TENDRAN*/
@media screen and (max-width:769px) {
  .imagen--ampliada--modal{
    position: relative;
    height: 50vh;
    width: 70vh;
  }
  .elementos--modal--empresa{
    align-items: center;
    flex-flow: column wrap;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
  }
  .flecha--navegacion--mas,.flecha--navegacion--menos{
    display: none;
  }

  .titulo--empresa--principal{
    font-size: 2rem;
  }
}

@media screen and (max-width:550px){
  .elementos--modal--empresa{
    width: 300px;
  }

  .imagen--ampliada--modal{
    width: 50vh;
    height: 40%;
  }

  .miniatura{
    width: 40px;
  }
}
